
    .header-wrapper {
        width: 100%;
        background: #fff;
        border-bottom: 1px solid #dcdfe6;
        .header {
            position: relative;
            width: 1200px;
            height: 60px;
            line-height: 60px;
            margin: 0 auto;
            display: flex;
            .fixed-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .header-logo {
                    width: 200px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .nav {
                    width: 1%;
                    flex: 1;
                    margin: 0 30px;
                    .el-menu-item {
                        padding: 0;
                        .nav-item {
                            display: block;
                            padding: 0 20px;
                        }
                    }
                }
                .home-login-btn {
                    height: 100%;
                    background-color: #E21D1A;
                    border-color: #E21D1A;
                }
            }
            /*.logo {*/
            /*    !*width: 200px;*!*/
            /*    font-size: 18px;*/
            /*    font-weight: 700;*/
            /*    margin-right: 20px;*/
            /*    overflow: hidden;*/
            /*}*/
            /*.user {*/
            /*    text-align: right;*/
            /*    margin-left: 20px;*/
            /*    span {*/
            /*        font-size: 16px;*/
            /*        margin-right: 20px;*/
            /*    }*/
            /*}*/
        }
    }
