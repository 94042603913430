
    .prev-box {
        height: 100vh;

        ::v-deep .el-dialog {
            height: 100%;
            display: flex;
            flex-direction: column;

            .el-dialog__header {
                padding: 10px 20px 0;
            }

            .el-dialog__body {
                height: 1%;
                flex: 1;
            }
        }

        .dialog-title {
            display: flex;
            justify-content: space-between;

            span {
                margin: 0px auto;
            }
        }

        .fixed-iframe {
            width: 100%;
            height: 100%;
        }

        .ep-tmpl {
            width: 100%;
            height: 100%;
            background-color: #FFF;

            .paper-wrapper {
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
            }

            .paper-container {
                background-color: #FFF;
                width: 100%;

                .paper-title {
                    width: 100%;
                    text-align: center;
                    font-size: 24px;
                    color: #409eff;
                    font-weight: 600;
                }

                .paper-content {
                    margin-top: 20px;
                    padding: 0 20px;
                }
            }
        }
    }
