

    #minder-container {
        width: 100%;
        height: 600px;
        /*position: absolute;*/
        background: #ffffff !important;
        /*left: 10px;*/
        /*top: 10px;*/
        /*bottom: 10px;*/
        /*right: 10px;*/
        position: relative;

        .btn-box {
            position: absolute;
            bottom: 0;
            left: 50%;
        }
    }

