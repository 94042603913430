
    .quality-course-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #fff;
        .quality-course-content {
            flex: 1;
            height: 1%;
            background-color: #F2F2F2;
            overflow: auto;
        }
    }
