
    .temp-popover {
        display: flex;
        flex-direction: column;

        .popover-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            height: 30px;

            &:hover {
                background: #ECF5FF;

                span {
                    color: #005469;
                }
            }

            span {
                color: #666666;
                font-size: 14px;
                padding-left: 10px;
            }

            i {
                color: #666666;
                font-size: 12px;
                padding-right: 10px;
            }
        }
    }

    .featured-content {
        display: flex;
        justify-content: start;
        width: 100%;
        height: 100%;

        .course-index {
            display: flex;
            justify-content: start;
            width: 100%;
            height: 100%;
            background-image: url("../../../assets/img/image/course_index_bg.png");
            background-repeat: no-repeat;
            background-color: #fff;
            background-position: center;
            background-size: cover;

            .index-left {
                width: 500px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 40px;

                .course-name {
                    color: #0E0804;
                    font-size: 24px;
                }

                .course-detail {
                    font-size: 16px;
                    color: #444444;
                    line-height: 1.8;
                }

                .view-detail-btn {
                    width: 280px;
                    height: 36px;
                    background: #005469;
                    border-radius: 10px;
                    color: #fff;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    margin-top: 20px;

                    &:hover {
                        background: #66b1ff;
                        border-color: #66b1ff;
                        color: #FFF;
                        cursor: pointer;
                    }
                }
            }
        }

        .featured-index {
            display: flex;
            justify-content: start;
            width: 100%;
            height: 100%;

            .featured-left {
                display: flex;
                flex-direction: column;
                position: relative;
                flex: 1;
                width: 1%;
                background-color: #F2F8FF;
                margin-right: 20px;

                .featured-item {
                    &:nth-child(2) {
                        margin-bottom: 64px;
                    }

                    &:nth-child(3) {
                        margin-bottom: 30px;

                        .item-title {
                            display: flex;
                            justify-content: space-between;

                            .upload-material {
                                width: 70px;
                                height: 30px;
                                background: #ECF5FF;
                                border: 1px solid #DCECFE;
                                border-radius: 4px;
                                color: #005469;
                                font-size: 14px;
                                text-align: center;
                                line-height: 30px;

                                &:hover {
                                    color: #005469;
                                    border-color: #c6e2ff;
                                    background-color: #ecf5ff;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .item-title {
                        position: relative;
                        font-size: 16px;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 4px;
                            width: 4px;
                            height: 16px;
                            background-color: #005469;
                        }

                        .text {
                            margin-left: 15px;
                        }
                    }

                    .featured-list {
                        width: 100%;
                        height: 120px;
                        background-color: #F5F7FA;
                        display: flex;
                        align-items: center;
                        box-sizing: border-box;


                        .featured-list-item {
                            display: flex;
                            margin-right: 30px;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: calc((100% - 100px) / 6);
                            /*min-width: 140px;*/
                            margin-right: 37px;
                            height: 120px;
                            background: #ffffff;
                            border-radius: 6px;

                            .inner-box {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }

                            &:hover {
                                box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                            }

                            .item-cover {
                                width: 50px;
                                height: 50px;
                                line-height: 40px;
                                text-align: center;
                                border-radius: 50%;
                                cursor: pointer;

                                i {
                                    font-size: 20px;
                                    color: #fff;
                                }

                                img {
                                    width: 50px;
                                    height: 50px;
                                }

                                .app-icon {
                                    max-width: 100%;
                                    max-height: 100%;
                                    border-radius: 5px;
                                }
                            }

                            &:nth-of-type(6) {
                                margin-right: 0;
                            }

                            .circle-box {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #ffffff;

                                ::v-deep .iconfont {
                                    font-size: 20px;
                                }
                            }

                            .item-name {
                                margin-top: 10px;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .module-content {
                    display: flex;
                    justify-content: space-between;
                    padding: 19px 20px;
                    /*position: absolute;*/
                    /*bottom: 20px;*/
                    /*left: 0;*/
                    /*right: 0;*/
                    align-items: center;
                    box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);

                    .module {
                        flex: 1;
                        width: 1%;

                        ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                            white-space: nowrap;
                        }
                    }

                    .module-list {
                        padding: 6px;
                        display: flex;

                        .module-item {
                            display: inline-block;
                            min-width: 60px;
                            min-height: 60px;
                            border: 1px dashed #CCCCCC;
                            border-radius: 10px;
                            margin-right: 15px;
                        }
                    }

                    .module-save-btn {
                        width: 60px;
                        height: 60px;
                        background: #ECF5FF;
                        border: 1px solid #DCECFE;
                        border-radius: 4px;
                        color: #005469;
                        font-size: 14px;
                        line-height: 60px;
                        text-align: center;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .featured-topbox {
                    z-index: 1;
                    margin-top: 16px;
                    display: flex;
                    align-items: center;
                    padding: 30px 30px 30px 0;
                    position: relative;
                    background: #FFFFFF;
                    box-shadow: 0px 5px 10px 1px rgba(32, 32, 32, 0.05);

                    span {
                        display: inline-block;
                        line-height: 1;
                        font-size: 14px;
                        padding: 8px 12px;
                        border-radius: 4px;
                        font-weight: 400;
                        cursor: pointer;
                    }

                    .class-item {
                        background: #005469;
                        box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                        border-radius: 20px;
                        color: #ffffff;
                    }
                }

                .featured-top {
                    flex: 1;
                    height: 1%;
                    background: #ffffff;

                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                        margin-right: -37px !important;
                    }

                    ::v-deep .el-scrollbar__view {
                        margin-right: 20px;
                    }

                    .featured-mainbox {
                        width: 100%;

                        .mainbox-list {
                            padding: 30px 60px;
                            min-height: 520px;

                            .dragg-box {
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;
                            }

                            .mainbox-list-item {
                                cursor: pointer;
                                width: 50%;
                                margin-bottom: 50px;
                                display: flex;

                                .item-title {
                                    margin-left: 30px;
                                    display: flex;
                                    align-items: center;
                                }
                            }

                            .no-data {
                                width: 100%;
                                font-size: 16px;
                                text-align: center;
                            }
                        }

                        .page-box {
                            text-align: center;
                        }
                    }
                }
            }

            .featured-right {
                width: 230px;
                min-width: 189px;
                background-color: #fff;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }

                .item-title {
                    position: relative;
                    font-size: 16px;
                    padding: 20px 20px 0;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 27px;
                        width: 6px;
                        height: 10px;
                        background: #005469;
                        border-radius: 3px;
                    }

                    .text {
                        margin-left: 15px;
                    }
                }

                .app-list {
                    padding: 0 20px;
                    display: flex;
                    flex-wrap: wrap;

                    .app-item {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin: 10px 16px 20px;
                        cursor: pointer;
                        border-radius: 10px;

                        &:hover {
                            transform: scale(1.2);
                            transition: 1s;
                            box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                            border-radius: 6px;
                        }

                        .app-cover {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 60px;
                            height: 60px;
                        }

                        img.app-icon {
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 5px;
                        }

                        span.app-name {
                            color: #333;
                            font-size: 12px;
                            margin-top: 10px;
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 160px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .app-detail-dialog {
        ::v-deep .el-dialog {
            ::v-deep .el-dialog__header {
                border-bottom: 1px solid #e6e7ea;
            }

            .title {
                text-align: center;
                margin: 0;
            }

            .content {
                display: flex;
                flex-direction: column;

                .h2 {
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                    margin: 10px 0;
                }

                .soft-introduction {
                    text-align: center;
                }

                .soft-content {
                    padding: 10px 50px;
                    min-height: 200px;
                }
            }

            ::v-deep .el-dialog__footer {
                border-top: 1px solid #e6e7ea;
                padding: 20px;

                a {
                    padding: 7px 20px;
                    font-size: 14px;
                    border-radius: 4px;
                    margin-right: 20px;
                }

                a.home {
                    border: 1px solid #DCDFE6;
                    color: #606266;

                    &:hover {
                        color: #005469;
                        border-color: #c6e2ff;
                        background-color: #ecf5ff;
                    }
                }

                a.shop {
                    color: #FFF;
                    background-color: #005469;
                    border-color: #005469;

                    &:hover {
                        background: #66b1ff;
                        border-color: #66b1ff;
                        color: #FFF;
                    }
                }
            }
        }
    }
