
    .micro-lesson-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .micro-lesson-list {
            flex: 1;
            width: 100%;
            height: 1%;
            background-color: #fff;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .micro-lesson {
                padding: 0 20px;
                display: flex;
                flex-wrap: wrap;
                .micro-lesson-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 30px 16px 0;
                    cursor: pointer;
                    border-radius: 10px;
                    &:hover {
                        transform: scale(1.2);
                        transition: 1s;
                    }
                    .micro-lesson-cover {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 107px;
                        height: 107px;
                    }
                    img.micro-lesson-icon {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 5px;
                    }
                    span.micro-lesson-name {
                        color: #333;
                        font-size: 12px;
                        margin-top: 10px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 107px;
                        text-align: center;
                    }
                }
            }
        }
    }
    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }
